import React from 'react';

interface FooterProps {}
const Footer: React.FC<FooterProps> = (props) => {
    return (
        <div className="bg-black mt-12">
            <div
                style={{
                    height: 300,
                }}
                className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 container text-white p-3"
            >
                <div className="grid grid-spans-3">
                    <div>Create by</div>
                    <div>Thuan Phat</div>
                    <div>Le Duan</div>
                </div>
                <div>4EDUCATION</div>
                <div>4EDUCATION</div>
                <div>4EDUCATION</div>
                <div>4EDUCATION</div>
                <div>4EDUCATION</div>
            </div>
        </div>
    );
};
export default Footer;
