import React, { useEffect, useState } from 'react';
import { GetApi, GetGuestApi, PostApi } from '../../../untils/Api';
import { useStore } from 'react-redux';
import { change_is_loading } from '../../../reducers/Actions';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import { useTranslation } from 'react-i18next';
import OrderDetailItem from './OrderDetailItem';
import { formatPrice, toastWarning } from '../../../untils/Logic';
import { Button } from '../../ComponentsLogin';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { AlertChangeOrder } from '../../alert/Alert';
import { Divider } from '@mui/material';
interface OrderItemProps {
    order: any;
    setValueTabs: any;
    setIsReq: any;
}
const OrderItem: React.FC<OrderItemProps> = (props) => {
    const { order, setValueTabs, setIsReq } = props;
    const { t } = useTranslation();
    const [listOrderDetail, setListOrderDetail] = useState<any>(undefined);
    const [shop, setShop] = useState<any>(undefined);
    const [ship, setShip] = useState<any>(undefined);
    const [totalPrice, setTotalPrice] = useState<any>(undefined);

    const store = useStore();
    const handleCancleOrder = async () => {
        const orderRes = await PostApi(`/user/cancle-order/${order.id}`, localStorage.getItem('token'), {});
        if (orderRes.data.message == 'Success') {
            setValueTabs(3);
            setIsReq(true);
        }
        if (orderRes.data.message == 'MORE REQUEST') {
            toastWarning(t('order.PleaseWait'));
        }
    };
    const handleReOrder = async () => {
        const orderRes = await PostApi(`/user/re-order/${order.id}`, localStorage.getItem('token'), {});
        if (orderRes.data.message == 'Success') {
            setValueTabs(0);
            setIsReq(true);
        }
        if (orderRes.data.message == 'MORE REQUEST') {
            toastWarning(t('order.PleaseWait'));
        }
    };
    const getData = async () => {
        store.dispatch(change_is_loading(true));
        ///
        const orderDetails = await PostApi('/user/order-detail-many', localStorage.getItem('token'), {
            orderDetailIdList: order.orderDetailIdList,
        });
        if (orderDetails.data.message == 'Success') {
            setListOrderDetail(orderDetails.data.orderDetails);
        }
        //
        const shopRes = await GetGuestApi(`/api/shop/${order.shopId}`);
        if (shopRes.data.message == 'Success') {
            setShop(shopRes.data.shop);
        }
        //
        const shipRes = await GetGuestApi(`/api/ship/${order.shipId}`);
        if (shipRes.data.message == 'Success') {
            setShip(shipRes.data.ship);
        }
        //
        const totalPriceRes = await GetApi(`/user/get-price-order/${order.id}`, localStorage.getItem('token'));
        if (totalPriceRes.data.message == 'Success') {
            setTotalPrice(totalPriceRes.data.price);
        }
        if (totalPriceRes.data.message) store.dispatch(change_is_loading(false));
    };
    useEffect(() => {
        getData();
    }, []);
    return (
        <div className="p-6 mt-6 mb-6 box-shadow rounded-xl">
            <div className="font-normal flex items-center mb-3 text-blue-500">
                <SubtitlesIcon /> &nbsp; {t('order.Code')} : {order.id}
            </div>
            <Divider />
            <div className="grid grid-cols-12 mt-4">
                <div className="col-span-8 lg:col-span-9">
                    {listOrderDetail ? (
                        <div className="p-6">
                            {listOrderDetail.map((orderDetail: any, index: number) => {
                                if (index != listOrderDetail.length - 1) {
                                    return (
                                        <>
                                            <OrderDetailItem orderDetail={orderDetail} />
                                            <Divider />
                                        </>
                                    );
                                } else {
                                    return <OrderDetailItem orderDetail={orderDetail} />;
                                }
                            })}
                        </div>
                    ) : null}
                </div>
                <div style={{ minHeight: 400 }} className="col-span-4 lg:col-span-3 bg-gray-100 rounded-xl relative">
                    <div
                        className={` w-full text-xl text-center p-3 ${order.paid ? 'text-green-400' : 'text-blue-400'}`}
                    >
                        {order.paid ? t('order.Paid') : t('order.NotPaid')}
                        <div>{order.paid ? <PriceCheckIcon /> : <ErrorOutlineIcon />}</div>
                    </div>
                    <div style={{ bottom: 185 }} className="absolute w-full text-center">
                        {order.status == 'PROCESSING' ? (
                            order.paid ? null : (
                                <Button
                                    onClick={() => {
                                        AlertChangeOrder(handleCancleOrder);
                                    }}
                                    style={{ padding: '6px 30px' }}
                                >
                                    Cancle
                                </Button>
                            )
                        ) : order.status == 'CANCLE' ? (
                            <Button
                                onClick={() => {
                                    AlertChangeOrder(handleReOrder);
                                }}
                            >
                                Re-Order
                            </Button>
                        ) : null}
                    </div>
                    <div
                        style={{ bottom: 70 }}
                        className="absolute w-full text-center font-normal p-3 border-t border-b border-blue-200"
                    >
                        <div className="font-bold">{ship ? ship.name : null}</div>
                        <div className="font-normal text-sm">{t('order.PriceShip')}</div>
                        <div className="text-sm">{ship ? formatPrice(ship.price) : null}</div>
                    </div>
                    <div className="text-red-400 absolute bottom-0 w-full text-center p-3 bg-blue-100 rounded-tl-full rounded-tr-full">
                        {t('product.Total')} : {totalPrice ? formatPrice(totalPrice - ship.price) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderItem;
