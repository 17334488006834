import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useTranslation } from 'react-i18next';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

interface DrawMoreProps {
    setCategoryIdCurrent: any;
    listCategoryChild: any;
    handleReq: any;
    categoryPId: any;
    setPageCurrent: any;
}
const DrawMore: React.FC<DrawMoreProps> = (props) => {
    const { setCategoryIdCurrent, listCategoryChild, handleReq, categoryPId, setPageCurrent } = props;
    const { t } = useTranslation();
    const [state, setState] = useState<boolean>(false);
    const [valueRadio, setValueRadio] = useState<any>('');
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        console.log('here');
        setState(open);
    };
    const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCategoryIdCurrent((event.target as HTMLInputElement).value);
        setValueRadio((event.target as HTMLInputElement).value);
        handleReq();
        setPageCurrent(1);
        // setState(false);
    };
    const handleClickRadio = (e: any) => {
        if (valueRadio == e.target.value) {
            setCategoryIdCurrent(categoryPId);
            handleReq();
            setValueRadio('');
            setPageCurrent(1);
        }
    };
    const list = () => (
        <Box sx={{ width: '100%', minWidth: 400 }} role="presentation">
            <div className="mt-3 ml-3 mb-3 flex justify-start items-center ">
                <KeyboardBackspaceIcon className="cursor-pointer" onClick={toggleDrawer(false)} />
                <span className="cursor-pointer pl-3" onClick={toggleDrawer(false)}>
                    {t('homepage.Exit')}
                </span>
            </div>
            <Divider />

            <RadioGroup onChange={handleChangeRadio} value={valueRadio}>
                {listCategoryChild.map((category: any) => (
                    <FormControlLabel
                        className="pl-6 pr-6"
                        value={category.id}
                        control={<Radio />}
                        label={category.name}
                        onClick={(e: any) => handleClickRadio(e)}
                    />
                ))}
            </RadioGroup>
        </Box>
    );

    return (
        <>
            <div
                style={{ width: 50, height: 50, top: -22 }}
                className="absolute flex justify-center items-center right-0 top-0 hover:opacity-70 cursor-pointer p-3 rounded-full bg-blue-500 transition-all duration-500"
                onClick={toggleDrawer(true)}
            >
                <PlaylistAddIcon sx={{ width: 24, height: 24 }} />
            </div>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                <div style={{ maxWidth: 650 }}> {list()} </div>
            </Drawer>
        </>
    );
};

export default DrawMore;
