import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
interface BannerProps {}
const Banner: React.FC<BannerProps> = (props) => {
    return (
        <div>
            <Carousel
                className="cursor-pointer"
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                autoPlay={true}
                infiniteLoop={true}
                stopOnHover={true}
            >
                <div>
                    <img
                        className="rounded-xl"
                        style={{ objectFit: 'contain' }}
                        src="https://savani.vn/images/banners//original/banner-web1920x680-min_1728524750.webp"
                    />
                </div>
                <div>
                    <img
                        className="rounded-xl"
                        style={{ objectFit: 'contain' }}
                        src="https://savani.vn/images/banners//original/banner-web1920x680-min_1728524750.webp"
                    />
                </div>
                <div>
                    <img
                        className="rounded-xl"
                        style={{ objectFit: 'contain' }}
                        src="https://savani.vn/images/banners//original/banner-web1920x680-min_1728524750.webp"
                    />
                </div>
                <div>
                    <img
                        className="rounded-xl"
                        style={{ objectFit: 'contain' }}
                        src="https://savani.vn/images/banners//original/banner-web1920x680-min_1728524750.webp"
                    />
                </div>
            </Carousel>
            <div className="mt-12 grid grid-cols-6">
                <div className="col-span-6 lg:col-span-2 flex justify-center">
                    <img
                        className="rounded"
                        style={{ objectFit: 'contain', width: '90%' }}
                        src="https://savani.vn/images/banners//original/banner-web1920x680-min_1728524750.webp"
                    />
                </div>
                <div className="col-span-3 mt-12 lg:mt-0 lg:col-span-2 flex justify-center">
                    <img
                        className="rounded"
                        style={{ objectFit: 'contain', width: '90%' }}
                        src="https://savani.vn/images/banners//original/banner-web1920x680-min_1728524750.webp"
                    />
                </div>
                <div className="col-span-3 mt-12 lg:mt-0 lg:col-span-2 flex justify-center">
                    <img
                        className="rounded"
                        style={{ objectFit: 'contain', width: '90%' }}
                        src="https://savani.vn/images/banners//original/banner-web1920x680-min_1728524750.webp"
                    />
                </div>
            </div>
        </div>
    );
};

export default Banner;
