import React from 'react';
import { useTranslation } from 'react-i18next';
import ProductTop from '../../components/user-guest/product/ProductTop';
import Banner from '../../components/user-guest/Banner';
import MultiCaroselProductJV from '../../components/user-guest/product/MultiCaroselProductJV';
import Footer from '../../components/user-guest/footer/Footer';
import ProductNew from '../../components/user-guest/product/ProductNew';
interface HomePageProps {}
const HomePage: React.FC<HomePageProps> = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="marginTop">
                <div className="container">
                    <div className="">
                        <Banner />
                    </div>
                    <div className="mt-12">
                        <div className=" border-b border-gray-300 flex mt-12">
                            <div
                                style={{
                                    borderBottomWidth: 3,
                                }}
                                className="font-bold text-2xl border-b border-solid  border-red-500"
                            >
                                {t('product.ProductTop')}
                            </div>
                        </div>
                        <ProductTop />
                    </div>
                    <div id='product-new-id' className="mt-12">
                        <div className=" border-b border-gray-300 flex mt-12">
                            <div
                                style={{
                                    borderBottomWidth: 3,
                                }}
                                className="font-bold text-2xl border-b border-solid  border-red-500"
                            >
                                {t('homepage.New Product')}
                            </div>
                        </div>
                        <ProductNew />
                    </div>
                    <MultiCaroselProductJV />
                </div>
            </div>
            <Footer />
        </>
    );
};
export default HomePage;
