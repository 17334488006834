import React from 'react';
import { convertToPercentage } from '../../../untils/Logic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
interface DiscountItemProps {
    discount: any;
    shopId: any;
    setDiscountCurrent: any;
}

const DiscountItem: React.FC<DiscountItemProps> = (props) => {
    const { discount, shopId, setDiscountCurrent } = props;
    return (
        <a
            href='#product-shop'
            style={{ maxHeight: 100 }}
            onClick={() => setDiscountCurrent(discount)}
            className=" text-center text-sm text-gray-500 rounded-xl hover:opacity-70 transition-all duration-500 cursor-pointer relative grid grid-cols-12"
        >
            <div className="col-span-4 bg-red-400 grid grid-cols-12 rounded-tl-xl rounded-bl-xl">
                <div className="col-span-10"></div>
                <div className="col-span-2 bg-red-400 grid grid-rows-8 ">
                    <div style={{ backgroundColor: '#f5f5f5' }} className="  rounded-bl-full"></div>
                    <div className="row-span-6"></div>
                    <div style={{ backgroundColor: '#f5f5f5' }} className="  rounded-tl-full"></div>
                </div>
            </div>

            <div className="col-span-8 bg-blue-100 grid grid-cols-12 rounded-tr-xl rounded-br-xl">
                <div className="col-span-1 bg-blue-100 grid grid-rows-8">
                    <div style={{ backgroundColor: '#f5f5f5' }} className="row-span-1  rounded-br-full"></div>
                    <div className="row-span-6"></div>
                    <div style={{ backgroundColor: '#f5f5f5' }} className="row-span-1  rounded-tr-full"></div>
                </div>
                <div className="col-span-11 p-3 text-xs xl:text-sm">
                    <div>{discount.name}</div>
                    <div className="text-red-400 font-bold"> - {convertToPercentage(discount.percent)}</div>
                    <div className="absolute bottom-3 right-3">
                        <FontAwesomeIcon icon={faBolt} beatFade style={{ color: '#1291f3' }} />
                    </div>
                </div>
            </div>
        </a>
    );
};

export default DiscountItem;
