import React, { useEffect, useState } from 'react';
import { formatPrice } from '../../../untils/Logic';
import { GetGuestApi } from '../../../untils/Api';

interface GroupedShopProps {
    groupedShop: any;
    discounts: any;
    getPriceDiscount_1: any;
    getQuantity: any;
}
const GroupedShop: React.FC<GroupedShopProps> = (props) => {
    const { groupedShop, discounts, getPriceDiscount_1, getQuantity } = props;
    const [shops, setShops] = useState<any>([]);
    const getDataShop = async () => {
        groupedShop.map(async (shop: any) => {
            const shopRes = await GetGuestApi(`/api/shop/${shop.shopId}`);
            if (shopRes.data.message == 'Success') {
                setShops((prev: any) => [...prev, shopRes.data.shop]);
            }
        });
    };
    const getShopName = (shopId: any) => {
        if (shops.length > 0) {
            const index = shops.findIndex((item: any) => item.id == shopId);
            if (index != -1) {
                return shops[index].name;
            } else {
                return '';
            }
        } else {
            return '';
        }
    };
    useEffect(() => {
        getDataShop();
    }, []);
    return (
        <>
            {groupedShop.map((shop: any, index: number) => (
                <>
                    <div className="">{getShopName(shop.shopId)}</div>
                    {shop.productDetails.map((productDetail: any) => (
                        <a
                            href={`/product/${productDetail.productId}`}
                            className={`grid grid-cols-3 ${
                                index > 0 ? 'mt-6' : ''
                            } border-b border-gray-200 p-3 hover:opacity-70 transition-all duration-500`}
                        >
                            <div className="col-span-1 flex justify-center">
                                <img
                                    style={{ objectFit: 'cover', width: '90%', height: 200 }}
                                    className="rounded-xl"
                                    src={productDetail.images[0]}
                                />
                            </div>
                            <div className="col-span-2">
                                <div className="p-3">
                                    <div className="font-normal">{productDetail.name}</div>
                                    {discounts ? (
                                        <div className="text-red-400 mt-3">{getPriceDiscount_1(productDetail)}</div>
                                    ) : (
                                        <div className="text-red-400 mt-3">
                                            {formatPrice(productDetail.price)} x {getQuantity(productDetail)}
                                        </div>
                                    )}

                                    <div className="flex items-center text-blue-400 mt-2">
                                        <div className="font-thin pr-3 border-r border-gray-300">
                                            Size : {productDetail.option1}
                                        </div>
                                        <div className="font-thin ml-2">Color : {productDetail.option2}</div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    ))}
                </>
            ))}
        </>
    );
};

export default GroupedShop;
