import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HOST_BE, SOCKET_HOST, typeRole } from '../common/Common';
import ForgetPassword from '../pages/user-guest/auth/ForgetPassword';
import HomePage from '../pages/user-guest/Homepage';

import { ReducerProps } from '../reducers/ReducersProps';
import LoginRegister from '../pages/user-guest/auth/LoginRegister';
import { Login } from '@mui/icons-material';
import InfoUser from '../pages/user-guest/InfoUser';
import AllAddress from '../pages/user-guest/address/AllAddress';
import AddressCreate from '../pages/user-guest/address/AddressCreate';
import AddressEdit from '../pages/user-guest/address/AddressEdit';
import RegisterShop from '../pages/shop/RegisterShop';
import ShopHome from '../pages/shop/ShopHome';
import Product from '../pages/user-guest/Product';
import Page404 from '../pages/default/page404';
import ShopView from '../pages/user-guest/ShopView';
import Category from '../pages/user-guest/category/Category';
import CategoryView from '../pages/user-guest/category/CategoryView';
import Checkout from '../pages/user-guest/order/Checkout';
import Order from '../pages/user-guest/order/Order';
import { io } from 'socket.io-client';
import { set_socket_id_client } from '../reducers/Actions';
import Header from '../components/user-guest/header/Header';
//--------------------------------------------------------------
//--------------------------------------------------------------
interface MainRoutersProps {}
export const socket_IO_Client = io(SOCKET_HOST);
//--------------------------------------------------------------

const MainRouters: React.FC<MainRoutersProps> = (props) => {
    const store = useStore();
    const [id, setId] = useState<any>(undefined);
    const role = useSelector((state: ReducerProps) => state.role);
    const user = useSelector((state: ReducerProps) => state.user);
    useEffect(() => {
        //
        socket_IO_Client.on('sendIdFromServer', (data) => {
            store.dispatch(set_socket_id_client(data));
        });

        return () => {
            socket_IO_Client.disconnect();
        };
    }, []);
    useEffect(() => {
        if (user.id) {
            socket_IO_Client.emit('sendUserIdFromClient', user.id);
        }
    }, [user]);
    const defaultPage = (
        <>
            <Route path="/" element={<HomePage />}></Route>
            <Route path="/err404" element={<Page404 />}></Route>
            <Route path="/product/:productId" element={<Product />}></Route>
            <Route path="/shop-view/:shopId" element={<ShopView />}></Route>
            <Route path="/category" element={<Category />}></Route>
            <Route path="/category-view/:categoryId" element={<CategoryView />}></Route>
        </>
    );
    //return router suitable for role
    if (role === typeRole.GUEST) {
        return (
            <>
                <BrowserRouter>
                    <Header />
                    <Routes>
                        {defaultPage}
                        <Route path="/login" element={<Login />}></Route>
                        <Route path="/login-register" element={<LoginRegister />}></Route>
                        <Route path="/forget-password" element={<ForgetPassword />}></Route>
                    </Routes>
                </BrowserRouter>
            </>
        );
    } else if (role === typeRole.USER) {
        return (
            <>
                <BrowserRouter>
                    <Header />

                    <Routes>
                        {defaultPage}
                        <Route path="/user/info-user" element={<InfoUser />}></Route>
                        <Route path="/user/address" element={<AllAddress />}></Route>
                        <Route path="/user/address/create" element={<AddressCreate />}></Route>
                        <Route path="/user/address/edit/:addressId" element={<AddressEdit />}></Route>
                        <Route path="/user/register-shop" element={<RegisterShop />}></Route>
                        <Route path="/checkout" element={<Checkout />}></Route>
                        <Route path="/user/order" element={<Order />}></Route>
                    </Routes>
                </BrowserRouter>
            </>
        );
    } else if (role === typeRole.SHOP) {
        return (
            <BrowserRouter>
                <Header />
                <Routes>
                    {defaultPage}
                    <Route path="/user/info-user" element={<InfoUser />}></Route>
                    <Route path="/user/address" element={<AllAddress />}></Route>
                    <Route path="/user/address/create" element={<AddressCreate />}></Route>
                    <Route path="/user/address/edit/:addressId" element={<AddressEdit />}></Route>
                    <Route path="/shop/:shopId" element={<ShopHome />}></Route>
                    <Route path="/checkout" element={<Checkout />}></Route>
                    <Route path="/user/order" element={<Order />}></Route>
                </Routes>
            </BrowserRouter>
        );
    } else {
        return <></>;
    }
};
export default MainRouters;
